import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { HTMLContent } from "../components/Content";

import Layout from "../components/Layout";
import Features from "../components/Features";
import ArdsleyTitle from "../components/ArdsleyTitle";

export const WelcomePageTemplate = ({ image, mainpitch, bodyHTML }) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
        // backgroundAttachment: `fixed`,
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat'
      }}
    />


    <div className="container">
      <section className="section">
        <ArdsleyTitle text={mainpitch.title} style={{ margin: 'auto' }} />
        <p style={{ fontSize: "18px" }}>{mainpitch.description}</p>
        <div className="has-text-left" style={{ paddingTop: "30px" }}>
          <HTMLContent content={bodyHTML} />
        </div>
        <br />
        <pre style={{ backgroundColor: '#eee', padding: '20px' }}>
          <i>“People should never have to live in a box.”</i>
          <br />
          -The Ardsley Developer
        </pre>
      </section>
    </div>
  </div>
);

WelcomePageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  bodyHTML: PropTypes.string,
};

const WelcomePage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <WelcomePageTemplate
        bodyHTML={html}
        image={frontmatter.image}
        mainpitch={frontmatter.mainpitch}
      />
    </Layout>
  );
};

WelcomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WelcomePage;

export const pageQuery = graphql`
  query WelcomePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "welcome-page" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
